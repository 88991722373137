import React from 'react';

import Seo from '../../components/Seo';
import Layout from '../../components/layout/Layout';
import SplitSection from '../../components/SplitSection';
import Callout from '../../components/Callout';
import CalloutSimple from '../../components/CalloutSimple';

import anonymisation from '../../assets/drawings/anonymisation.png';
import cloud from '../../assets/drawings/cloud.png';
import datascreenshot from '../../assets/screenshots/datascreenshot.png';
import integrationtests from '../../assets/drawings/integrationtests.png';
import empowerment from '../../assets/drawings/empowerment.png';
import tick from '../../assets/drawings/tick.png';

export default () => {
  const title = 'Test more rapidly and frequently, from the cloud';
  const description =
    "Strio's on-demand environments allow you to perform better tests, more rapidly and frequently.";
  const url = 'https://strio.app/usecases/testmore';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <div className="bg-lownight md:h-20 h-16" />
      <section className="relative pa-0 overflow-hidden">
        <div className="squared-background-left" />
        <div className="squared-background-right" />

        <div className="flex items-center relative z-1 lg:my-40 mt-32">
          <div className="container py-6 px-4">
            <div className="text-center md:text-left text-lownight">
              <h1 className="lg:mt-10 text-4xl lg:text-5xl xl:text-6xl font-bold leading-none tracking-tight">
                Test more rapidly and frequently, from the cloud
              </h1>
              <p className="font-normal leading-snug text-base lg:text-2xl mt-12">
                Strio's on-demand environments allow you to perform better tests, more rapidly and
                frequently. Instead of having shared and hard to manage environments, create
                environments when you need it.
              </p>
              <Callout />
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white shadow-inner">
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={empowerment}
              style={{ height: '200px' }}
              alt="team icon"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Empower each team, each developer to run their own tests
              </h3>
              <div className="mt-6 text-lg leading-relaxed text-lownight">
                With Strio, each developer or each team can now create their own test environments.
                No more broken staging environment, no more waiting that the environment is fixed or
                available. Everyone can now autonomously run their own tests via their own on-demand
                environment.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={cloud}
              style={{ height: '200px' }}
              alt="cloud icon"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Test from the cloud
              </h3>
              <div className="mt-6 text-lg leading-relaxed">
                Testing locally is great and serves many needs. But it does not allow to reproduce
                with high fidelity what will happen once it will move to the cloud. Strio lets you
                test from the cloud and without any constraint, with the same ease than locally.
              </div>
            </div>
          }
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={integrationtests}
              style={{ height: '200px' }}
              alt="Integration tests icon"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Run integration tests
              </h3>
              <div className="mt-6 text-lg leading-relaxed text-lownight">
                Running integration tests can be difficult when you have heavy dependencies: a
                database, an ElasticSearch cluster, other internal services, ... Just create an
                environment once for your Pull Request or your branch, and run your integrations
                tests against it to reduce your time of test from too long to nothing.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={datascreenshot}
              alt="A screenshot of data configuration"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Add Production data
              </h3>
              <div className="mt-6 text-lg leading-relaxed text-lownight">
                Simulate Production behavior by adding your own Production data to run the most
                realistic tests. Specify the databases and the tables that we need to copy, or the
                storage that we need to access to copy blob files. Add a special processing like
                renaming, anonymisation or a custom script to the process to make it even more
                adapted to your environment.
              </div>
            </div>
          }
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={anonymisation}
              style={{ height: '100px' }}
              alt="Anonymisation icon"
              className="mx-auto my-4"
            />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Anonymization of data
              </h3>
              <div className="mt-6 text-lg leading-relaxed">
                Because privacy and compliance matter, Strio anonymizes your data set, so that no
                personal data will be shown while doing your tests. Just specify the sensible
                columns in your data pipeline and we will replace those columns with SHA256 hashes.
                Preserve statistical properties, while staying privacy-compliant.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img src={tick} style={{ height: '200px' }} alt="Tick icon" className="mx-auto my-4" />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Managing environments becomes simple
              </h3>
              <div className="mt-6 text-lg leading-relaxed">
                Managing numerous environments in addition to Production can be overwhelming at
                times. Forget about that with Strio. Create and delete environments on-demand, so
                you do not have to worry about maintaining them. Because they are based on the last
                Production state, environments created with Strio are always up-to-date.
              </div>
            </div>
          }
        />
      </section>
      <section className="bg-gray-100">
        <CalloutSimple />
      </section>
    </Layout>
  );
};
